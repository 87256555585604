var columns = [{
  title: '用户名称',
  dataIndex: 'username',
  key: 'username',
  width: '20%',
  scopedSlots: {
    customRender: 'username'
  }
}, {
  title: '备注',
  dataIndex: 'remark',
  key: 'remark',
  width: '20%',
  scopedSlots: {
    customRender: 'remark'
  }
}, {
  title: '添加时间',
  dataIndex: 'addtime',
  key: 'addtime',
  width: '20%',
  scopedSlots: {
    customRender: 'addtime'
  }
}, {
  title: '状态',
  dataIndex: 'status',
  key: 'status',
  width: '20%',
  scopedSlots: {
    customRender: 'status'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  //   fixed: "right",
  width: '20%',
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };