var searchKeys = [{
  key: "username",
  label: "用户名称",
  placeholder: "中文名称/英文名称",
  required: false,
  rules: [],
  input: true
},
// {
//     key: "ip",
//     label: "IP地址",
//     placeholder: "",
//     required: false,
//     rules: [],
//     input: true
// },
{
  key: "status",
  label: "状态",
  placeholder: "请选择",
  required: false,
  rules: [],
  select: true,
  initialValue: ''
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };